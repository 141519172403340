<template>
  <div>
    <el-dialog title="换题"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               :width="width"
               top="3vh"
               :before-close="handleClose">
      <div class="form"
           v-if="showSelect">
        <el-form :model="selectForm"
                 label-width="80px">
          <el-row :gutter="10">
            <el-col :span="14">
              <el-form-item label="换收藏题:"
                            prop="grade_id">
                <el-radio-group v-model="selectForm.type"
                                placeholder="选择"
                                @input="changeType">
                  <el-radio :key="1"
                            v-show="false"
                            :label="1">相关考点/知识点/章节</el-radio>
                  <el-radio :key="2"
                            :label="2">收藏的题</el-radio>
                  <el-radio :key="3"
                            :label="3">收藏的卷 </el-radio>

                </el-radio-group>

                <el-radio v-model="filterate"
                          style="margin-left: 8px"
                          @click.native.prevent="changeChecked(filterate)"
                          label="1">只显示未使用过</el-radio>
                <el-button type="primary"
                           size="small"
                           @click.native="reset">重置</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="8"
                    v-show="selectForm.type==3">
              <el-form-item label="选择试卷:">
                <el-select v-model="selectForm.paper"
                           style="width:100%"
                           placeholder="请选择"
                           filterable
                           @change="changePaper">
                  <el-option v-for="item in collectPaperList"
                             :key="item.edu_paper_id"
                             :label="item.paper_name"
                             :value="item.edu_paper_id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <!-- <el-col :span="3"
                    style="text-align:right"
                    v-show="selectForm.type==3">
              <el-button type="primary"
                         @click="search">确定</el-button>
            </el-col> -->
          </el-row>
        </el-form>
      </div>
      <span class="dialog-footer">
        <pagination ref="pagination"
                    v-if="dialogVisible"
                    :get-data="search"
                    :now-page.sync="page"
                    :now-size.sync="size"
                    :total="totalElements" />
      </span>
      <div class="topic_wrap">
        <BASETOPICCOMMON ref="BASETOPICCOMMONREF"
                         @checkTopic="checkTopic"
                         @diuqi="diuqi"
                         @refrence="refrence"
                         :list="topicList"
                         :showChange="true"
                         width="100%" />
      </div>
      <el-dialog title="标签"
                 :visible.sync="dialogVisible1"
                 width="300px"
                 append-to-body>
        <el-form label-width="80px">
          <el-form-item label="标签">
            <el-input clearable
                      placeholder="请输入标签"
                      v-model="tag" />
          </el-form-item>
        </el-form>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="dialogVisible1 = false">取 消</el-button>
          <el-button type="primary"
                     @click="save">确 定</el-button>
        </span>
      </el-dialog>

      <span slot="footer"
            class="dialog-footer">
        <pagination ref="pagination"
                    v-if="dialogVisible"
                    :get-data="search"
                    :isInit="false"
                    :now-page.sync="page"
                    :now-size.sync="size"
                    :total="totalElements" />
      </span>
    </el-dialog>
  </div>
</template>

<script>
import tipicBody from './tipicBody.vue'
import BASETOPICCOMMON from '@/components/BASE_PAPER_COMMON/index.vue'

export default {
  data () {
    return {
      dialogVisible: false,
      dialogVisible1: false,
      tag: '',
      edu_paper_detail_id: '',
      form: {},
      topicList: [],
      page: 1,
      size: 10,
      totalElements: 0,
      replaceUrl: '/api/v1/combination/knowledge_replace',
      submitUrl: '/api/v1/combination/knowledge_replace_done',
      width: '900px',
      collectPaperList: [],  //收藏的试卷
      topicListAll: [],//收藏的卷全部题目
      selectForm: { type: 1 },
      filterate: '1',
      showSelect: false //知识点、章节组卷显示换题选择
    }
  },
  components: {
    tipicBody, BASETOPICCOMMON
  },
  mounted () {
    if (this.$route.path == "/testPapercomposition/testingCentre/info" || this.$route.path == "/testPapercomposition/templatePaper/addDetails" || this.$route.path == "/testPapercomposition/chapter/info" || this.$route.query.module == "4" || this.$route.query.module == "5") {
      this.showSelect = true
    }
  },
  methods: {
      reset () {
      this.selectForm.type = 1
      this.filterate = '1'
      this.initData()
    },
    refrence () {
      this.$refs.pagination.shuaxin()
    },
    changeChecked (str) {
      console.log('str', str)
      if (str == '1') {
        this.form.filterate = '0'
        this.filterate = '0'
      } else {
        this.form.filterate = '1'
        this.filterate = '1'
      }
      this.changeType()
    },
    initData () {
      this.form.filterate = this.filterate
      let form = JSON.parse(JSON.stringify(this.form))
      form.page = this.page
      form.size = this.size
      this.$http({
        url: this.replaceUrl,
        method: 'post',
        data: form
      }).then(res => {
        this.topicList = res.data.list
        this.totalElements = res.data.count
        this.$nextTick(() => {
          let c = document.getElementsByClassName("topic_wrap")[0]
          c.scrollTop = 0

        })
      })
    },

    changeType () {
      this.topicList = []

      this.page = 1
      if (this.selectForm.type == 3) {
        this.getPaper()
      } else {
        this.search()
      }
    },
    changeChecked (str) {
      console.log('str', str)
      if (str == '1') {
        this.form.filterate = '0'
        this.filterate = '0'
      } else {
        this.form.filterate = '1'
        this.filterate = '1'
      }
      this.initData()
    },
    changePaper () {
      this.page = 1
      this.getPaperTopic()
    },
    // 主要是切换分页时调用
    search (page, size) {
      if (page) {
        this.page = page
      }
      if (size) {
        this.size = size
      }
      if (this.selectForm.type == 1) {
        this.initData()
      } else if (this.selectForm.type == 2) {
        this.getTopicCollect()
      } else if (this.selectForm.type == 3) {
        let start = this.size * (this.page - 1)
        let end = this.size * this.page
        this.getPaperTopic(start, end)
      }
    },
    // 收藏的试卷
    getPaper () {
      this.$http({
        url: '/api/v1/combination/collect_list',
        method: 'get',
        params: {
          edu_paper_id: this.form.edu_paper_id,
          limit: 1000,
          page: 1
        }
      }).then(res => {
        this.collectPaperList = res.data.list
        if (this.collectPaperList.length > 0) {
          this.selectForm.paper = this.collectPaperList[0].edu_paper_id
          this.changePaper()
        } else {
          this.$notify({
            title: '提示',
            message: '暂无收藏的试卷!',
            type: 'warning'
          });
          this.topicList = []
          this.totalElements = 0
        }
      })
    },

    // 选择的收藏试卷的题目
    getPaperTopic (start, end) {
      // 选择/切换时试卷获取题目数据，切换分页时截取数据
      if (!(start || end)) {
        this.$http({
          url: '/api/v1/combination/czb_paper_detail',
          method: 'get',
          params: {
            edu_paper_id: this.selectForm.paper
          }
        }).then(res => {
          // this.collectPaperList = res.data.list
          this.topicListAll = res.data.questions
          // this.topicListAll = this.topicListAll.map(item => [item])
          this.totalElements = this.topicListAll.length
          this.topicList = this.topicListAll.slice(0, this.size)
          this.$nextTick(() => {
            // 滑动到数据顶部
            let c = document.getElementsByClassName('topic_wrap')[0]
            c.scrollTop = 0
          })
        })
      } else {
        this.topicList = this.topicListAll.slice(start, end)
        this.$nextTick(() => {
          // 滑动到数据顶部
          let c = document.getElementsByClassName('topic_wrap')[0]
          c.scrollTop = 0
        })
      }
    },
    // 收藏的题目
    getTopicCollect () {
      this.$http({
        url: '/api/v1/favorites/favorite_correlation_question',
        method: 'get',
        params: {
          question_id: this.form.question_id,
          edu_paper_id: this.form.edu_paper_id,
          limit: this.size,
          page: this.page
        }
      }).then(res => {
        if (res.data.list.length == 0) {
          this.$notify({
            title: '提示',
            message: '暂无收藏的题目!',
            type: 'warning'
          });
          this.topicList = []
          this.totalElements = 0
          return
        }
        this.topicList = res.data.list
        // this.topicList = this.topicList.map(item => [item])
        this.totalElements = res.data.count
        this.$nextTick(() => {
          // 滑动到数据顶部
          let c = document.getElementsByClassName('topic_wrap')[0]
          c.scrollTop = 0
        })
      })
    },

    handleClose () {
      this.page = 1
      this.size = 10
      this.tag = ''
      this.topicList = []
      this.filterate = '1'
      this.dialogVisible = false
      this.selectForm = { type: 1 }
    },
    delItem () {
      this.tag = ''
      this.dialogVisible1 = true
    },
    save () {

    },
    checkTopic (item) {
      let form = {
        edu_paper_detail: this.edu_paper_detail_id,
        question_id: item.question_id
      }
      this.$http({
        url: this.submitUrl,
        method: 'post',
        data: form
      }).then(res => {
        this.$notify({
          title: '提示',
          message: '操作成功!',
          type: 'success'
        });
        this.handleClose()
        this.$parent.getInfo()
      })
    },
    diuqi (item) { },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__footer {
  text-align: center;
}
.el-input {
  width: 100px;
}
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
.topic_wrap {
  height: calc(100vh - 180px);
  overflow-y: auto;
  overflow-x: hidden;
}
::v-deep .el-dialog__body {
  padding-bottom: 10px;
  /* 整个滚动条 */
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  /* 滚动条上的按钮 (上下箭头). */
  ::-webkit-scrollbar-button {
    display: none;
  }
  /* 滚动条上的滚动滑块. */
  ::-webkit-scrollbar-thumb {
    background-color: rgb(202, 202, 202);
    border-radius: 50px;
  }

  /* 滚动条没有滑块的轨道部分 */
  ::-webkit-scrollbar-track-piece {
    border-radius: 20px;
  }
  /* 当同时有垂直滚动条和水平滚动条时交汇的部分. */
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
}
::v-deep {
  .el-form-item {
    margin-bottom: 2px;
  }
}
</style>