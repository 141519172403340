<template>
  <div>
    <el-dialog title="排序"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="400px"
               :before-close="handleClose">
      <el-form :model="form"
               label-width="80px">
        <el-form-item label="新序号为:">
          <el-select v-model="form.new_serial"
                     style="width:100%"
                     placeholder="请选择">
            <el-option v-for="item in options"
                       :key="item"
                       :label="item"
                       :value="item">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary"
                   @click="change">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: false,
      options: [],
      form: {
        index: 0
      }
    }
  },
  methods: {
    handleClose () {
      this.dialogVisible = false
    },
    sort () {
      console.log('form', this.form);
      this.$http({
        url: '/api/v1/combination/serial_lst',
        method: 'post',
        data: {
          edu_paper_id: this.$route.query.edu_paper_id,
          serial_in_paper: this.form.serial_in_paper,
          question_id: this.form.question_id,
        }
      }).then(res => {
        this.options = res.data.list
      })
    },
    change () {
      this.$http({
        url: '/api/v1/combination/submit_serial',
        method: 'post',
        data: {
          edu_paper_id: this.$route.query.edu_paper_id,
          question_id: this.form.question_id,
          serial_in_paper: this.form.serial_in_paper,
          new_serial: this.form.new_serial
        }
      }).then(res => {
        this.handleClose()
        this.$parent.getInfo()
        this.$notify({
          title: '提示',
          message: '成功!',
          type: 'success'
        });
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__footer {
  text-align: center;
}

::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
</style>