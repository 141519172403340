<template>
  <div>
    <div class="card">
      <div class="card_head"
           v-if="$route.path == '/testPapercomposition/testingCentre/info'">
        <span v-if="item.question_score == 0"
              style="width:100px;font-weight:bold">
          本题共{{item.count}}小题
          <slot v-if="recordEduPaper.paper_enable == 0"
                name="right_btn"></slot>
        </span>
        <span v-else
              style="color:#9EA1A4">
          <span class="yuan">
            {{item.serial_in_paper}}
          </span>
          题目来源：{{item.question_source_year}};
          <span v-if="recordEduPaper.subject_id ==7 || recordEduPaper.subject_id ==11">
            题目类别：{{item.question_category_name}}
          </span>
          <span style="color:#393B3D;font-weight:bold;margin-left:20px">题目分数：</span><span style="color:#FE674E">{{item.question_score}}</span>
          <br />
          <span class="kaodian"
                v-if="item.check_point">
            题目知识点：{{item.check_point}}
          </span>
          <span class="typeName">
            {{item.question_type_name}}</span>

          <slot v-if="recordEduPaper.paper_enable == 0 && item.not_show_replace != '1' "
                name="right_btn"></slot>
        </span>
      </div>

      <div class="card_head"
           v-if="$route.path == '/testPapercomposition/autonomously/addDetails'">
        <span v-if="item.question_score == 0"
              style="width:100px;font-weight:bold">
          本题共{{item.count}}小题
          <slot v-if="recordEduPaper.paper_enable == 0"
                name="right_btn"></slot>
        </span>
        <span v-else
              style="color:#9EA1A4">
          <span class="yuan">
            {{item.serial_in_paper}}
          </span>
          题目来源：{{item.question_source_year||item.source_name}};
          <span v-if="recordEduPaper.subject_id ==7 || recordEduPaper.subject_id ==11">
            题目类别：{{item.question_category_name}}
          </span>
          <span style="color:#393B3D;font-weight:bold;margin-left:20px">题目分数：</span><span style="color:#FE674E">{{item.question_score}}</span>
          <span class="typeName">
            {{item.question_type_name}}</span>
          <br />
          <span class="kaodian"
                v-if="item.check_point">
            题目知识点：{{item.check_point}}
          </span>

          <slot v-if="recordEduPaper.paper_enable == 0 && item.question_code_head == '' "
                name="right_btn"></slot>
        </span>
      </div>
      <div class="box-card"
           v-if="item.question_heading">
        <div class="clearfix">
          题目：
        </div>
        <div class="topic_content"
             v-html="$replaceAll(item.question_heading)">
        </div>
        <div class="bottom_border"></div>
      </div>
      <div class="box-card">
        <div class="clearfix">
          题目内容：
        </div>
        <div class="topic_content"
             v-html="$replaceAll(item.question_content)">
        </div>
        <div class="bottom_border"></div>
      </div>
      <div class="box-card"
           v-if="item.question_score != 0">
        <div class="clearfix">
          <span>题目答案：</span>
        </div>
        <div class="topic_content"
             style="color:#FF6147;font-weight:bold"
             v-html="$replaceAll(item.question_answer)">
        </div>
        <div class="bottom_border"></div>
      </div>
      <div class="box-card"
           v-if="item.question_score != 0"
           style="padding-bottom:15px">
        <div class="clearfix">
          <span>题目解释：</span>
        </div>
        <div class="topic_content"
             v-html="$replaceAll(item.question_explain)">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      topic: [],
      a: 5
    }
  },
  props: ['item', 'recordEduPaper',]
}
</script>

<style lang="scss" scoped>
.box-card {
  padding-top: 10px;
  background: white;
  .clearfix {
    font-size: 18px;
    font-weight: bold;
    height: 18px;
    line-height: 18px;
    border-left: 4px solid #37a0f4;
    text-indent: 1em;
    margin: 20px 0;
  }
  .topic_content {
    margin-left: 20px;
    font-size: 16px;
    padding-right: 20px;
  }
}

.card {
  margin: auto;
  font-size: 14px;
  margin-top: 20px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  .card_head {
    font-size: 16px;
    position: relative;
    display: flex;
    padding: 10px 20px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background-color: #eff8ff;
  }
}

.yuan {
  display: inline-block;
  width: 28px;
  height: 28px;
  background: #68bcff;
  color: white;
  text-align: center;
  line-height: 28px;
  border-radius: 50%;
}
.fixed_right {
  position: absolute;
  right: 20px;
  top: 5px;
}
.kaodian {
}
.bottom_border {
  width: 98%;
  height: 10px;
  border-bottom: 1px solid #e8e8e8;
  margin-left: 20px;
}
.typeName {
  display: inline-block;
  padding: 5px 15px;
  background: #68bcff;
  color: white;
  margin-left: 10px;
  border-radius: 20px;
}
</style>