<template>
  <div>
    <div class="card"
         v-for="(ele,index) in item"
         :key="index">
      <div class="card_head">
        <span v-if="ele.is_head"
              style="width:100px">
          本题共{{item.length-1}}小题
          <span class="fixed_right">
            <slot name="right_btn"></slot>
          </span>
        </span>
        <span v-else
              style="color:#9EA1A4">
          题目来源：{{ele.source || ele.question_source_year}};
          <span v-if="ele.question_type_name"
                class="typeName">{{ele.question_type_name}}</span>
          <span style="color:#393B3D;font-weight:bold;margin-left:20px">题目分数：</span><span style="color:#FE674E">{{ele.question_score}}</span>
          <br />
          <span class="fixed_right">
            <slot name="right_btn"
                  v-if="!item[0].is_head"></slot>
          </span>
        </span>
      </div>
      <div class="box-card"
           v-if="ele.question_heading">
        <div class="clearfix">
          题目：
        </div>
        <div class="topic_content"
             v-html="$replaceAll(ele.question_heading)">
        </div>
        <div class="bottom_border"></div>
      </div>
      <div>

        <div class="box-card">
          <div class="clearfix">
            题目内容：
          </div>
          <div class="topic_content"
               v-html="$replaceAll(ele.question_content)">
          </div>
          <div class="bottom_border"></div>
        </div>
        <div class="box-card"
             v-if="!ele.is_head">
          <div class="clearfix">
            <span>题目答案：</span>
          </div>
          <div class="topic_content"
               style="color:#FF6147;font-weight:bold"
               v-html="$replaceAll(ele.question_answer)">
          </div>
          <div class="bottom_border"></div>
        </div>
        <div class="box-card"
             v-if="!ele.is_head"
             style="padding-bottom:15px">
          <div class="clearfix">
            <span>题目解释：</span>
          </div>
          <div class="topic_content"
               v-html="$replaceAll(ele.question_explain)">
          </div>
          <div class="bottom_border"></div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      topic: [],
      a: 5,
      head: {
        is_head: false
      },
      topicBody: []
    }
  },
  props: ['item', 'recordEduPaper'],
}
</script>

<style lang="scss" scoped>
.box-card {
  padding-top: 10px;
  background: white;
  .clearfix {
    font-size: 18px;
    font-weight: bold;
    height: 18px;
    line-height: 18px;
    border-left: 4px solid #37a0f4;
    text-indent: 1em;
    margin: 20px 0;
  }
  .topic_content {
    margin-left: 20px;
    font-size: 16px;
    padding-right: 20px;
  }
}

.card {
  margin: auto;
  font-size: 14px;
  margin-top: 20px;
  padding-bottom: 20px;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  .card_head {
    font-size: 16px;
    position: relative;
    display: flex;
    padding: 10px 20px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    background-color: #eff8ff;
  }
}

.yuan {
  display: inline-block;
  width: 28px;
  height: 28px;
  background: #68bcff;
  color: white;
  text-align: center;
  line-height: 28px;
  border-radius: 50%;
}
.fixed_right {
  position: absolute;
  right: 20px;
  top: 8px;
}
.kaodian {
  display: inline-block;
  color: black;
  font-weight: bold;
  margin-top: 15px;
}
.bottom_border {
  width: 98%;
  height: 10px;
  border-bottom: 1px solid #e8e8e8;
  margin-left: 20px;
}
.typeName {
  display: inline-block;
  padding: 5px 15px;
  background: #68bcff;
  color: white;
  border-radius: 20px;
}
</style>