<template>
  <div>
    <div class="paper_warp"
         v-for="(topic,index) in list"
         :style="{'width':width}"
         :key="index">
      <div class="has_children"
           v-if="topic.question_children&&topic.question_children.length">
        <div class="head">
          本题共{{topic.question_children.length}}小题
          <div>
            <span class="fixed_right"
                  v-if="showChange">
              <span class="fonts"
                    style="color:#3F9EFF"
                    @click="checkTopic(topic)">选择</span>
              <span style="color:#FF6147"
                    class="fonts"
                    @click="diuqi(topic)">丢弃</span>
            </span>
            <span class="fixed_right"
                  v-else>
              <!-- 收藏 -->
              <span class="img">
                <img v-show="topic.question_favorite_id>0"
                     src="@/assets/checkTestPage/icon-collected2.png"
                     title="取消收藏"
                     @click="cancelCollect(topic)">
                <img src="@/assets/checkTestPage/icon-tocollect2.png"
                     alt="收藏"
                     title="收藏"
                     v-show="topic.question_favorite_id==0"
                     @click="collect(topic)">
              </span>
              <span v-if="$parent.list.paper_enable == 0&&showOpertion "
                    class="fonts"
                    style="color:#3F9EFF"
                    @click="sort(topic)">排序</span>
              <span v-if="($route.path == '/testPapercomposition/testingCentre/info' || $route.path == '/testPapercomposition/chapter/info'||showOpertion) && $parent.list.paper_enable == 0"
                    class="fonts"
                    style="color:#FF6147"
                    @click="check(topic)">换题</span>
              <span class="fonts"
                    style="color:#3F9EFF"
                    @click="jiucuo(topic)">纠错</span>
            </span>
          </div>
        </div>

        <div class="box-card">
          <div class="clearfix topic_id">
            <span>题目ID:{{topic.question_id}}</span><span :class="'difficulty_tag difficulty_tag_'+topic.question_difficulty">{{topic.question_difficulty_str}}</span>
          </div>
          <div class="clearfix">
            题目：
          </div>
          <div class="topic_content"
               v-html="topic.question_content">
          </div>
          <div class="bottom_border"></div>
        </div>
        <Topic ref="TopicRef"
               v-for="item in topic.question_children"
               :key="item.answer_id"
               :item="item">

          <template v-slot:right_btn>
            <span class="fixed_right fonts"
                  v-if="!showChange">
              <span class="fonts"
                    style="color:#3F9EFF"
                    @click="jiucuo(item)">纠错</span>
            </span>
          </template>
        </Topic>
      </div>

      <div class="warp"
           v-else>
        <Topic ref="TopicRef"
               :item="topic">
          <template v-slot:right_btn>
            <span class="fixed_right"
                  v-if="showChange">
              <span style="color:#3F9EFF"
                    class="fonts"
                    @click="checkTopic(topic)">选择</span>
              <span style="color:#FF6147"
                    class="fonts"
                    @click="diuqi(topic)">丢弃</span>
            </span>
            <span class="fixed_right"
                  v-else>
              <!-- 收藏 -->
              <span class="img">
                <img v-show="topic.question_favorite_id>0"
                     src="@/assets/checkTestPage/icon-collected2.png"
                     title="取消收藏"
                     @click="cancelCollect(topic)">
                <img src="@/assets/checkTestPage/icon-tocollect2.png"
                     alt="收藏"
                     title="收藏"
                     v-show="topic.question_favorite_id==0"
                     @click="collect(topic)">
              </span>
              <span v-if="$parent.list.paper_enable == 0&&showOpertion && $route.query.module!=6"
                    class="fonts"
                    style="color:#3F9EFF"
                    @click="sort(topic)">排序</span>
              <span v-if="$parent.list.paper_enable == 0 && ($route.path == '/testPapercomposition/testingCentre/info' || $route.path == '/testPapercomposition/chapter/info'||showOpertion)"
                    class="fonts"
                    style="color:#FF6147"
                    @click="check(topic)">换题</span>
              <span class="fonts"
                    style="color:#3F9EFF"
                    @click="jiucuo(topic)">纠错</span>
            </span>
          </template>

        </Topic>
      </div>
      <el-dialog title="标签"
                 :visible.sync="dialogVisible1"
                 width="300px"
                 append-to-body>
        <el-form label-width="80px">
          <el-form-item label="标签">
            <el-input clearable
                      placeholder="请输入标签"
                      v-model="tag" />
          </el-form-item>
        </el-form>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="dialogVisible1 = false">取 消</el-button>
          <el-button type="primary"
                     @click="save">确 定</el-button>
        </span>
      </el-dialog>
    </div>
    <Collect ref="collectRef" />
    <jiucuo ref="jiucuoRef" />
  </div>
</template>

<script>
import Topic from './topic.vue'
import jiucuo from '@/components/jiucuo.vue'
import Collect from './collectTag.vue'
export default {
  data () {
    return {
      tag: '',
      item1: {},
      dialogVisible1: false
    }
  },
  components: {
    Topic, jiucuo, Collect
  },
  props: {
    // 接收题目，在使用组件的地方去循环
    list: {
      type: Array,
      default: () => {
        return []
      }
    },
    showOpertion: {
      type: Boolean,
      default: false
    },
    showChange: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: ''
    }
  },
  methods: {
    sort (row) {
      this.$parent.sort(row)
    },
    check (row) {
      this.$parent.check(row)
    },
    getInfo () {
      this.$parent.getInfo()
    },
    checkTopic (row) {
      this.$emit('checkTopic', row)
    },
    diuqi (row) {
      this.tag = ''
      this.item1 = row
      this.dialogVisible1 = true
    },
    save () {
      this.$http({
        url: '/api/v1/question/add',
        method: 'post',
        data: {
          question_id: this.item1.question_id,
          tags: this.tag
        }
      }).then(res => {
        this.$notify({
          title: '提示',
          message: '丢弃成功!',
          type: 'success'
        });
        this.dialogVisible1 = false
        this.$emit('refrence')
      })
    },
    jiucuo (row) {
      let form = {
        question_id: row.question_id,
        comment: ''
      }
      this.$refs.jiucuoRef.form = form
      this.$refs.jiucuoRef.dialogVisible = true
    },
    collect (row) {
      this.$refs.collectRef.topic = row
      this.$refs.collectRef.dialogVisible = true
    },
    cancelCollect (row) {
      this.$confirm("确定取消收藏？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http({
          url: '/api/v1/favorites/favorite_del',
          method: 'post',
          data: {
            id: row.question_favorite_id
          }
        })
      }).then(() => {
        row.question_favorite_id = 0
        this.$notify({
          title: '提示',
          message: '成功!',
          type: 'success'
        });
      })
    },

  }
}
</script>

<style lang="scss" scoped>
.paper_warp {
  width: calc(100% - 150px);
  margin-top: 20px;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 20%);
  .has_children {
    .head {
      font-weight: bold;
      background: #eff8ff;
      padding: 10px 10px 10px 20px;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      div {
        text-align: right;
      }
    }
  }
}

.box-card {
  padding-top: 10px;
  background: white;
  .clearfix {
    font-size: 18px;
    font-weight: bold;
    height: 18px;
    line-height: 18px;
    border-left: 4px solid #37a0f4;
    text-indent: 1em;
    margin: 20px 0;
  }
  .topic_content {
    margin-left: 20px;
    font-size: 16px;
    padding-right: 20px;
  }
}

.fixed_right {
  width: 110px;
  text-align: right;
  padding-right: 10px;
  .img {
    width: 26px;
    height: 20px;
    display: inline-block;
    margin-right: 10px;
    vertical-align: bottom;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.fonts {
  font-weight: bold;
  padding: 10px 0px;
  margin: 0 10px;
  cursor: pointer;
  font-size: 16px !important;
  text-align: right;
}
.topic_id {
  color: #393b3d;
  font-weight: bold;
  margin-right: 20px;
  display: flex;
  justify-content: space-between;
}
.difficulty_tag {
  font-size: 16px;
  font-weight: 500;
  padding: 0 30px;
}
// 容易
.difficulty_tag_1 {
  color: #5ac524;
}
// 较易
.difficulty_tag_2 {
  color: #b8d625;
}
// 中等
.difficulty_tag_3 {
  color: #ffde34;
}
// 较难
.difficulty_tag_4 {
  color: #ffa244;
}
// 困难
.difficulty_tag_5 {
  color: #fe4c4c;
}
</style>